<template>
  <ValidationProvider
    #default="validationContext"
    :ref="refName"
    :name="name"
    :vid="name"
    :rules="rules"
    tag="div">
    <b-form-group
      :label="formLabel"
      :state="$getValidationState(validationContext)">
      <v-select
        v-model="innerValue"
        v-bind="$attrs"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :options="options"
        :reduce="!returnValue ? val => val : val => val[returnValue]"
        :class="innerClass"
        :label="labelOption" />
      <b-form-invalid-feedback :state="$getValidationState(validationContext)">
        {{ validationContext.errors[0] }}
      </b-form-invalid-feedback>
    </b-form-group>
  </ValidationProvider>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number, Object, Array, Boolean]
    },
    name: {
      type: String,
      required: true
    },
    rules: {
      type: [String, Object],
      default: () => {}
    },
    formLabel: {
      type: String,
      default: ''
    },
    innerClass: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    returnValue: {
      type: String,
      default: ''
    },
    labelOption: {
      type: String,
      default: 'label'
    }
  },
  computed: {
    innerValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    refName () {
      return `validation-provider-${this.name}`
    }
  }
}
</script>

<style scoped>
.v-select {
  background: white;
}
</style>
